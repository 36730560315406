import useHttp from "~/composables/useHttp";
import { useReport } from "~/composables/useReport";
import type { APIConfigResponse } from "~/types/Common";

export async function getConfig(): Promise<{
  isSuccess: boolean;
  data: null | APIConfigResponse;
  message: string;
}> {
  const { data, error } = await useHttp({
    url: `/config.json`,
    canRetry: true,
  });

  if (error.message) {
    useReport({
      level: "error",
      message: error.message,
    });
    return {
      isSuccess: false,
      message: error.message,
      data: null,
    };
  }
  return {
    isSuccess: true,
    message: "",
    data: data as APIConfigResponse,
  };
}
